.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .search-voucher > span {
  color: #2d9cdb;
} */

.ant-btn {
  border-radius: 10px;
}

.ant-input {
  border-radius: 10px;
}

.input-voucher-price,
.input-voucher-value,
.input-voucher-time-remaining,
.input-redeem-per-day,
.input-voucher-number-material {
  width: -webkit-fill-available !important;
}

.spin {
  text-align: center;
  opacity: 0.5;
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

#createVoucherForm_image .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 0px;
}

#createVoucherForm_image
  .ant-upload.ant-upload-drag
  p.ant-upload-drag-icon
  .anticon {
  font-size: 35px;
}

#createVoucherForm_image .ant-upload.ant-upload-drag p.ant-upload-text {
  margin-bottom: 0px;
  font-size: 15px;
}

#createVoucherForm .ant-form-item {
  margin-bottom: 10px;
}

.description-form-item .ant-form-item-control {
  height: 80%;
}

.description-form-item .ant-form-item-control-input {
  height: 100%;
}

.description-form-item .ant-form-item-control-input-content {
  height: 100%;
}

.description-form-item .voucherDescription {
  height: 100%;
}

#createVoucherForm_image {
  margin-bottom: 0px;
}

.ant-input-suffix {
  color: rgba(114, 132, 154, 0.4);
}

.text-value {
  color: #1a3353;
}

.col-image .ant-image {
  width: 152px;
  height: 152px;
  display: table;
  margin: auto;
  /* background: #2d9cdb; */
}

.ant-carousel .ant-image {
  margin: auto;
  display: table;
}

.description-form-item .ql-toolbar.ql-snow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-color: rgb(230, 235, 241);
}

.description-form-item .ql-container.ql-snow {
  height: calc(100% - 40px);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: rgb(230, 235, 241);
}

.ant-input-group-addon {
  background: transparent;
}

/* #updateVoucherForm_voucherLink {
  color: #2d9cdb;
} */

.voucherDescription .ql-editor {
  height: 400px;
}

.voucherDescriptionEdit .ql-editor {
  height: 170px;
}

.GiftVoucherCampaignDescription .ql-editor {
  height: 280px;
}

.GiftVoucherCampaignDescriptionEdit .ql-editor {
  height: 170px;
}

.voucherCampaignDescription {
  height: 100%!important;
}

.voucherCampaignDescription .ql-editor {
  min-height: 290px;
}

.voucherCampaignDescriptionEdit .ql-editor {
  height: 160px;
}

.input_error {
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 107, 114);
}

.status-draft-color {
  background: #ededed !important;
  color: #72849a !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.status-pending-color {
  background: rgba(255, 197, 66, 0.1) !important;
  color: #ffc542 !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.status-approved-color {
  background: rgba(4, 209, 130, 0.1) !important;
  color: #31cb9e !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.status-start-color {
  background: rgba(62, 121, 247, 0.1) !important;
  /* color: #2d9cdb !important; */
  border-radius: 2px !important;
  border-color: transparent !important;
}

.status-pause-color {
  background: rgba(252, 127, 133, 0.1) !important;
  color: #eb7c89 !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.status-stop-color {
  background: rgba(255, 107, 114, 0.1) !important;
  color: #e7485b !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.status-lock-color {
  background: rgba(255, 107, 114, 0.1) !important;
  color: #e7485b !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.status-unlock-color {
  background: rgba(255, 197, 66, 0.1) !important;
  color: #ffc542 !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.status-release-published-color {
  background: rgba(62, 121, 247, 0.1) !important;
  /* color: #2d9cdb !important; */
  border-radius: 2px !important;
  border-color: transparent !important;
}

.status-release-received-color {
  background: rgba(4, 209, 130, 0.1) !important;
  color: #31cb9e !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.status-release-pending-color {
  background: rgba(255, 197, 66, 0.1) !important;
  color: #ffc542 !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.status-release-used-color {
  background: #f5f5f5 !important;
  color: #72849a !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.status-release-expried-color {
  background: rgba(255, 107, 114, 0.1) !important;
  color: #e7485b !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.ant-btn-primary {
  border-color: transparent !important;
}

.ant-btn {
  border-radius: 10px !important;
}



.ant-table-column-title {
  color: #72849a;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent !important;
}

.ant-table-cell {
  color: #72849a !important;
}

.upload-image-row .ant-form-item-control {
  padding-left: 10px;
  padding-right: 10px;
}

.ant-form-item {
  margin-bottom: 16px !important;
}


.label {
  padding-left: 8px !important;
  padding-right: 4px !important;
}

.ant-table-content {
  width: 100% !important;
  overflow: auto !important;
}

.upload-release-row {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 20px !important;
}

.center-upload-button {
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
  background: rgba(62, 121, 247, 0.1) !important;
  color: #2D9CDB !important;
  text-shadow: none !important;
}

/* .uploadEmail {
  background: rgba(62, 121, 247, 0.1) !important;
  border-radius: 10px;
} */

/* .uploadSMS {
  background: rgba(86, 101, 134, 0.1) !important;
  border-radius: 10px !important;
} */

.upload-input {
  display: none;
}

.row-err {
  background: rgba(222, 68, 54, 0.1);
}

.sent-to-validate-err {
  color: #e7485b;
}

.bnt-del-file {
  border: 1px solid #ff525a !important;
  border-radius: 10px !important;
  color: #ff525a !important;
  background-color: transparent !important;
}

.bnt-down-file {
  border: none !important;
  border-radius: 10px !important;
  background-color: rgba(62, 121, 247, 0.1) !important;
}

.bnt-release {
  border: 1px solid #ff525a !important;
  border-radius: 10px !important;
  color: #ff525a !important;
  background-color: transparent !important;
}

.upload-bnt {
  color: #455560 !important;
  background: #ffffff !important;
  border: 1px solid #ededed !important;
}

@media (max-width: 800px) {
  .right-banner {
    display: none !important;
  }
}

.auth-container .ant-spin-container {
  height: 100% !important;
}

.auth-container .ant-spin-nested-loading {
  height: 100% !important;
}

.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before,
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0px;
  left: 0px;
  border-bottom: none !important;
  content: "";
}

.export-release-bnt {
  background-color: rgba(62, 121, 247, 0.1) !important;
  border: none !important;
  border-radius: 10px !important;
}

.status-export-completed {
  background: rgba(62, 121, 247, 0.1) !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.status-export-processing {
  background: #f5f5f5 !important;
  color: #72849a !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.ant-upload-list {
  padding: 10px !important;
}

.uploadFormItem {
  margin-bottom: 0px !important;
}

.slick-dots li {
  width: 40px !important;
  height: 4px !important;
  background: rgba(22, 22, 22, 0.301);
}

.slick-dots li button {
  width: 40px !important;
  height: 4px !important;
}

.slick-dots li.slick-active button {
  width: 40px !important;
  height: 4px !important;
}

.ant-carousel {
  width: 100% !important;
  margin: auto !important;
}

.reuqets-otp-content {
  height: 45% !important;
  width: 100% !important;
  margin: auto;
  padding: 5px;
  display: grid;
  text-align: center;
}

.captcha {
  margin: 0 auto;
  width: 100%;
  margin-top: 10px;
}

.ant-carousel .slick-next ::before {
  content: "";
}

.ant-carousel .slick-prev ::before {
  content: "";
}

.otp-input-num {
  width: 40px !important;
}

.otp-input > input {
  width: 40px !important;
  height: 50px;
  background-color: transparent;
  border: 1px solid rgb(230, 235, 241);
  border-radius: 0.625rem;
  appearance: textfield !important;
  font-size: 25px !important;
  margin: 5px !important;
}

.upload-image-row > .ant-form-item > .ant-form-item-label > label {
  margin-left: 10px !important;
}

.start-end-tag {
  background: rgba(62, 121, 247, 0.1) !important;
  color: #2D9CDB!important;
  border-radius: 2px !important;
  border-color: transparent !important;
  padding: 0px !important;
}

.ant-table-tbody > tr > td {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.statistic-budget > .ant-col-xl-5 {
  max-width: 20% !important
}